<div class="d-flex">
  <div class="row align-items-center bannerBg  m-0">
    <div class="col-md-5 col-12 col-lg-5 backhome"
         [routerLink]="['/blogs']"><i class="fa fa-arrow-left cursorPointer"
         aria-hidden="true"></i> <span class="backBlog ml-3 cursorPointer">Back to Blogs</span></div>
    <div class="col-md-7 col-12 col-lg-7 ">
      <span class="bannerTitle marl-35">Blogs</span>
    </div>
  </div>
</div>
<div class="container">
  <div class="col-md-12 col-12 col-lg-12 mart-50 ">
    <div class="row">
      <div class="col-md-11 col-11 col-lg-11 pl-0">
        <p class="blogViewTitle mb-0">Proprotion of gross inflows</p>
        <p class="blogViewCaption mb-0"><span class="text-primary">Investment</span> | Posted on December 25,2020</p>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-12 col-lg-12 mart-16">
    <div class="row">
      <div class="col-md-9 col-12 col-lg-9 ">
        <div class="row ">
          <img src="../../../../assets/image/blog-1.png"
               class="width100 brsd"
               alt="" />
        </div>
        <div class="row mart-32">
          <p class=" blogViewContent">You’ve been blogging for quite some time now, but you’re having trouble
            getting to the first page of the SERPs. Maybe you haven’t been paying attention to your domain
            authority. </p>
          <p class="mt-3 blogViewContent">Among the many hundreds of factors Google uses to determine a website’s
            ranking on the SERPs, domain authority is one of the significant ones. Over 1.2 billion websites
            exist across the internet. With so many webpages fighting for first place on the search engine
            results, it’s no surprise that you need to go beyond just keywords and site speed.</p>
          <p class="mt-3 blogViewContent">I’ve worked with high-growth startups, influencers, content marketers,
            and bloggers, to increase their domain authority and scale their growth at uSERP. And recently, we
            just acquired Wordable.io, and plan to do the same. </p>
          <p class="mt-3 blogViewContent">In this post, let’s focus on how you can increase your domain authority
            by developing amazing content, obtaining natural links, guest blog posts, and creating synergistic
            link building networks.</p>
          <p class="mt-3 blogViewContent">Ready? Let’s go!</p>
        </div>
        <div class="row mt-4">
          <p class="mt-3 blogViewContentTitle">Domain Authority</p>
          <p class="mt-3 blogViewContent">Before we get into how you can increase your domain authority (DA),
            let’s first talk about what it is. Domain authority is a metric developed by Moz that is commonly
            used to measure a website’s authority. It is measured on a scale of 0-100, 0 being low-authority,
            and 100 being high-authority.</p>
          <p class="mt-3 blogViewContent">If you want to grow your search engine rankings, you need to keep an eye
            on your domain authority. The higher your DA, the greater your chances of ranking high on the SERPs.
            This will automatically bring you more organic traffic.</p>
          <p class="mt-3 blogViewContent">Unlike the ranking score used by Google (called PageRank) that uses
            hundreds of factors, DA is calculated using only 40 factors. Some of these factors include the
            number of links to your website, the quality of those links, your website’s content quality and
            current SEO performance, and even the popularity of your site on social media.</p>
        </div>
        <div class="row mt-5">
          <span class="commentsTitle">Reader Comments </span> <img src="../../../assets/image/count.png"
               class="ml-2"
               alt="count" />
        </div>
        <div class="row  mart-16">
          <div class="col-md-12 col-12 col-lg-12 commentBorder">
            <div class="row mt-2">
              <div class="col-md-2 col-4 col-lg-1">
                <img src="../../../assets/image/comment_1.png"
                     alt="comments" />
              </div>
              <div class="col-md-10 col-8 col-lg-11 pl-0">
                <p class="commentsName">Marvin McKinney</p>
                <p class="commentsDate">December 25,2020</p>
              </div>
            </div>
            <div class="row ml-1">
              <p class="everyone mb-0">Hi everyone, </p>
              <p class="everyoneContent">we have been working hard with the team bringing a new articles. It
                has passed a few
                months till we released it in case of traveling and shooting, hopefully you’ll love it.</p>
            </div>
          </div>
        </div>
        <div class="row  mart-16">
          <div class="col-md-12 col-12 col-lg-12 commentBorder">
            <div class="row mt-2">
              <div class="col-md-2 col-4 col-lg-1">
                <img src="../../../assets/image/comment_2.png"
                     alt="comments" />
              </div>
              <div class="col-md-10 col-8 col-lg-11 pl-0">
                <p class="commentsName">Devon Lane</p>
                <p class="commentsDate">December 25,2020</p>
              </div>
            </div>
            <div class="row ml-1">
              <p class="everyoneContent">Don’t worry about that, we are happy to read a new stories. The time
                we had to wait was crazy, but the results are awesome! You did an epic job. Now I just need
                to a buy one.</p>
            </div>
          </div>
        </div>
        <div class="row mart-32 mb-3">
          <span class="postCommand">Tell us what you think by leaving a comment below!</span>
          <div class="col-md-12 col-12 col-lg-12 mt-3 pl-0 mart-40">
            <form [formGroup]="postBlogsForm">
              <div class="row">
                <div class="col-md-6 col-12 col-lg-6">
                  <div class="form-group">
                    <label for="exampleInputEmail1 col-md-12 col-12 col-lg-12 width100">
                      <div class="row">
                        <div class="col-md-6 col-6 col-lg-6">
                          Name <span class="text-danger">*</span>
                        </div>
                        <div class="col-md-6 col-6 col-lg-6">
                          <span class="text-right">
                            <div *ngIf="postBlogsForm.get('userName').invalid && (postBlogsForm.get('userName').dirty || postBlogsForm.get('userName').touched) && f.userName.errors"
                                 class="error">
                              <div *ngIf="f.userName.errors.required">Required <i class="fa fa-exclamation-circle"
                                   aria-hidden="true"></i></div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </label>
                    <input type="text"
                           class="form-control"
                           id="exampleInputEmail1"
                           aria-describedby="emailHelp"
                           formControlName="userName"
                           placeholder="Enter Name" />
                  </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6">
                  <div class="form-group">
                    <label for="exampleInputEmail1 col-md-12 col-12 col-lg-12 width100">
                      <div class="row">
                        <div class="col-md-6 col-6 col-lg-6">
                          Email ID <span class="text-danger">*</span>
                        </div>
                        <div class="col-md-6 col-6 col-lg-6">
                          <span class="text-right">
                            <div *ngIf="postBlogsForm.get('emailId').invalid && (postBlogsForm.get('emailId').dirty || postBlogsForm.get('emailId').touched) && f.emailId.errors"
                                 class="error">
                              <div *ngIf="f.emailId.errors.required">Required <i class="fa fa-exclamation-circle"
                                   aria-hidden="true"></i></div>
                              <div *ngIf="f.emailId.errors.email">In-valid Email</div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </label>
                    <input type="text"
                           class="form-control"
                           id="exampleInputEmail1"
                           aria-describedby="emailHelp"
                           formControlName="emailId"
                           placeholder="Enter Email ID" />
                  </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 mart-8">
                  <div class="form-group">
                    <label for="exampleInputEmail1 col-md-12 col-12 col-lg-12 width100">
                      <div class="row">
                        <div class="col-md-6 col-6 col-lg-6">
                          Comment <span class="text-danger">*</span>
                        </div>
                        <div class="col-md-6 col-6 col-lg-6">
                          <span class="text-right">
                            <div *ngIf="postBlogsForm.get('comment').invalid && (postBlogsForm.get('comment').dirty || postBlogsForm.get('comment').touched) && f.comment.errors"
                                 class="error">
                              <div *ngIf="f.comment.errors.required">Required <i class="fa fa-exclamation-circle"
                                   aria-hidden="true"></i></div>
                              <div *ngIf="f.comment.errors.email">In-valid Email</div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </label>
                    <textarea class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="4"
                              formControlName="comment"></textarea>
                  </div>
                </div>
              </div>
              <div class="row ml-0 mart-24 marb-125">
                <button type="button"
                        class="btn btn-secondary mr-2 borderradius30">Cancel</button>
                <button type="button"
                        class="btn btn-primary borderradius30">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 col-lg-3 padl-24 mb-3">
        <p class="postedBy">Posted By</p>
        <div class="row">
          <div class="col-md-12 col-12 col-lg-12">
            <img src="../../../assets/image/malik.png"
                 alt="poster" /> <span class="ml-2 posterName">Jacob Jones</span>
          </div>
        </div>

        <div class="row mart-32">
          <div class="col-md-12 col-12 col-lg-12">
            <div class="row">
              <span class="col-4 col-md-4 col-lg-4 likes"><img src="../../../assets/image/like.png"> 123</span>
              <span class="col-4 col-md-4 col-lg-4 likes"><img src="../../../assets/image/comments.png"> 6</span>
              <span class="col-4 col-md-4 col-lg-4 likes pl-0"><img src="../../../assets/image/share.png"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>