<!-- <div class="col-md-12 col-12 col-lg-12 bannerBg text-center pt3">
  <span class="title">Privacy Policy</span>
</div> -->
<div class="d-flex">
  <div class="row align-items-center bannerBg  m-0">
    <div class="col-12 text-center">
      <span class="title">Privacy Policy</span>
    </div>
  </div>
</div>
<div class="col-md-12 col-12 col-lg-12 ">
  <div class="row mart-60 marb-120">
    <div class="col-md-2 col-2 col-lg-2"></div>
    <div class="col-md-8 col-8 col-lg-8">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque cursus in diam, nullam vitae felis,
        diam, vulputate vulputate. Dictumst semper elementum nibh condimentum maecenas adipiscing netus platea.
        Dignissim tincidunt accumsan at ornare ullamcorper vestibulum sed. Et nulla maecenas enim, ullamcorper
        sagittis nisi luctus. Nulla diam orci posuere lacus nec sed facilisis. Curabitur ut tellus mus nam
        faucibus
        vitae euismod. Non eget semper aenean ornare pretium nunc. Pellentesque duis quis diam, ornare
        scelerisque
        amet.Quisque hendrerit aenean laoreet vel adipiscing ut. Enim, urna, ultrices sit tristique. Quam a
        augue at
        elementum at aenean placerat volutpat mattis. Felis, nisi, nunc, duis elit adipiscing. Sed amet, viverra
        habitant tellus. Nisl, non, purus, odio blandit risus, nam. Placerat nullam a eu, cursus nibh. Massa,
        fringilla augue enim, nunc.Vel, odio commodo enim leo cursus. Leo id sem volutpat nunc. Sed id lectus
        fringilla quis ullamcorper sed ultrices quam. Facilisi arcu aenean iaculis malesuada blandit consectetur
        scelerisque. Phasellus at amet mauris, quis. Aliquet quis amet lacus, et nisl sed aliquet. Auctor nunc
        id
        facilisis ultrices neque, diam ac viverra sem. Pharetra non quisque volutpat integer nulla amet gravida
        in.
        Nisl nec id cras integer massa risus sed. Amet id velit sed tortor, vitae consequat nulla nibh nec. Enim
        tincidunt sodales gravida in.Pharetra, sed augue leo, sed tincidunt proin quam. Iaculis bibendum
        ultrices
        fusce quis suspendisse. Luctus congue neque orci dolor. Neque dapibus pulvinar sit phasellus in. Tellus
        aliquam metus diam tortor eget eget condimentum tincidunt erat. Sed etiam at lectus enim elementum
        aenean.
        Orci tincidunt cursus imperdiet viverra donec arcu. Et mattis sed magnis at molestie quis nulla.
        Suspendisse
        nascetur lorem sagittis ipsum libero mi quam congue iaculis.At quis senectus nunc sed eget eget sapien
        vitae. Interdum mi sed proin est suspendisse justo. Duis nunc tellus diam suscipit eu. Elementum,
        libero,
        nulla viverra cras nunc, enim tristique massa. Eleifend orci pulvinar non sem feugiat varius lacus,
        proin.
        Sed pretium vulputate pretium mi nulla mollis ac egestas. Nibh magna pretium commodo, morbi mi arcu, leo
        sed
        id. Sem nibh adipiscing morbi est vestibulum ullamcorper id dignissim quis. Etiam a aenean proin sit
        at.Maecenas pellentesque facilisis aenean ipsum. Morbi nibh porta neque ultricies mauris id viverra. Sed
        in
        ut faucibus et. Curabitur diam tellus adipiscing scelerisque. Massa morbi suspendisse eros, tristique
        arcu
        proin sapien praesent nullam. Amet, urna luctus feugiat fermentum eget maecenas lectus. Pretium
        accumsan,
        purus, lorem neque. Imperdiet posuere morbi urna risus penatibus. Pellentesque leo at convallis arcu
        nisi
        nisl. Lorem arcu tellus bibendum gravida quisque id lectus. Eu, justo vulputate nisl sit elementum felis
        imperdiet dui.Id massa fermentum tempus, diam risus. Facilisi donec pretium viverra eu, commodo erat.
        Sed
        vulputate dui quis et ut massa egestas aliquam nec. Duis tortor faucibus vitae amet id dignissim
        pellentesque amet sit. Lobortis leo egestas molestie lacinia diam eget laoreet iaculis mi. Velit, nunc
        morbi
        ultrices scelerisque nec.Sed ut egestas suscipit ut tempor nulla et sed. Nullam consequat, purus nulla
        tellus, sed convallis tempus volutpat integer. Elementum nunc aliquet et, sit a. Donec amet adipiscing
        id
        mauris. Lacus justo tempus pellentesque tellus tincidunt lectus viverra ultrices id.
      </p>
      <p class="mt-3">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque cursus in diam, nullam vitae felis,
        diam, vulputate vulputate. Dictumst semper elementum nibh condimentum maecenas adipiscing netus platea.
        Dignissim tincidunt accumsan at ornare ullamcorper vestibulum sed. Et nulla maecenas enim, ullamcorper
        sagittis nisi luctus. Nulla diam orci posuere lacus nec sed facilisis. Curabitur ut tellus mus nam
        faucibus
        vitae euismod. Non eget semper aenean ornare pretium nunc. Pellentesque duis quis diam, ornare
        scelerisque
        amet.Quisque hendrerit aenean laoreet vel adipiscing ut. Enim, urna, ultrices sit tristique. Quam a
        augue at
        elementum at aenean placerat volutpat mattis. Felis, nisi, nunc, duis elit adipiscing. Sed amet, viverra
        habitant tellus. Nisl, non, purus, odio blandit risus, nam. Placerat nullam a eu, cursus nibh. Massa,
        fringilla augue enim, nunc.Vel, odio commodo enim leo cursus. Leo id sem volutpat nunc. Sed id lectus
        fringilla quis ullamcorper sed ultrices quam. Facilisi arcu aenean iaculis malesuada blandit consectetur
        scelerisque. Phasellus at amet mauris, quis. Aliquet quis amet lacus, et nisl sed aliquet. Auctor nunc
        id
        facilisis ultrices neque, diam ac viverra sem. Pharetra non quisque volutpat integer nulla amet gravida
        in.
        Nisl nec id cras integer massa risus sed. Amet id velit sed tortor, vitae consequat nulla nibh nec. Enim
        tincidunt sodales gravida in.Pharetra, sed augue leo, sed tincidunt proin quam. Iaculis bibendum
        ultrices
        fusce quis suspendisse. Luctus congue neque orci dolor. Neque dapibus pulvinar sit phasellus in. Tellus
        aliquam metus diam tortor eget eget condimentum tincidunt erat. Sed etiam at lectus enim elementum
        aenean.
        Orci tincidunt cursus imperdiet viverra donec arcu. Et mattis sed magnis at molestie quis nulla.
        Suspendisse
        nascetur lorem sagittis ipsum libero mi quam congue iaculis.At quis senectus nunc sed eget eget sapien
        vitae. Interdum mi sed proin est suspendisse justo. Duis nunc tellus diam suscipit eu. Elementum,
        libero,
        nulla viverra cras nunc, enim tristique massa. Eleifend orci pulvinar non sem feugiat varius lacus,
        proin.
        Sed pretium vulputate pretium mi nulla mollis ac egestas. Nibh magna pretium commodo, morbi mi arcu, leo
        sed
        id. Sem nibh adipiscing morbi est vestibulum ullamcorper id dignissim quis. Etiam a aenean proin sit
        at.Maecenas pellentesque facilisis aenean ipsum. Morbi nibh porta neque ultricies mauris id viverra. Sed
        in
        ut faucibus et. Curabitur diam tellus adipiscing scelerisque. Massa morbi suspendisse eros, tristique
        arcu
        proin sapien praesent nullam. Amet, urna luctus feugiat fermentum eget maecenas lectus. Pretium
        accumsan,
        purus, lorem neque. Imperdiet posuere morbi urna risus penatibus. Pellentesque leo at convallis arcu
        nisi
        nisl. Lorem arcu tellus bibendum gravida quisque id lectus. Eu, justo vulputate nisl sit elementum felis
        imperdiet dui.Id massa fermentum tempus, diam risus. Facilisi donec pretium viverra eu, commodo erat.
        Sed
        vulputate dui quis et ut massa egestas aliquam nec. Duis tortor faucibus vitae amet id dignissim
        pellentesque amet sit. Lobortis leo egestas molestie lacinia diam eget laoreet iaculis mi. Velit, nunc
        morbi
        ultrices scelerisque nec.Sed ut egestas suscipit ut tempor nulla et sed. Nullam consequat, purus nulla
        tellus, sed convallis tempus volutpat integer. Elementum nunc aliquet et, sit a. Donec amet adipiscing
        id
        mauris. Lacus justo tempus pellentesque tellus tincidunt lectus viverra ultrices id.
      </p>
    </div>
    <div class="col-md-2 col-2 col-lg-2"></div>
  </div>
</div>
