<div class="container-fluid bg-darks p-0 headrtop"
     *ngIf="isWebsite && currentURL != '/digitalXchange/register'">

  <nav class="navbar navbar-expand-lg bg-dark navbar-dark fixed-top">

    <a class="navbar-brand p-0"> <img [routerLink]="['/']"
           class="cursorPointer logoImg"
           src="../../../assets/image/enablere-header-log.svg"
           alt="logo" /></a>

    <button class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"
         id="collapsibleNavbar">
      <ul class="navbar-nav headMrg my-auto ">
        <li class="nav-item">
          <a class="nav-link"
             [ngClass]="{'menuActive':currentURL === '/'}"
             [routerLink]="['/']">Home</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link "
             [ngClass]="{'menuActive':currentURL === '/product'}"
             [matMenuTriggerFor]="menu"
             aria-label="Example icon-button with a menu"
             (mouseover)='imgSrc = "../../../assets/image/arrow-down-active.svg"'
             (mouseout)='imgSrc = "../../../assets/image/arrow-down.png"'>
            Products<img [src]="imgSrc"
                 (mouseover)='imgSrc = "../../../assets/image/arrow-down-active.svg"'
                 (mouseout)='imgSrc = "../../../assets/image/arrow-down.png"'
                 class="marl-8"
                 *ngIf="currentURL !== '/product'">
            <img src="../../../assets/image/arrow-down-active.svg"
                 class="marl-8"
                 *ngIf="currentURL === '/product'">
          </a>
          <mat-menu #menu="matMenu"
                    class="dropdown">
            <a class="dropdown-item"
               [routerLink]="['/product']">Digital Xchange (dx)</a>
          </mat-menu>
        </li>
        <li class="nav-item ">
          <a class="nav-link "
             [ngClass]="{'menuActive':currentURL === '/services'}"
             [matMenuTriggerFor]="menu1"
             aria-label="Example icon-button with a menu"
             (mouseover)='imgSrc1 = "../../../assets/image/arrow-down-active.svg"'
             (mouseout)='imgSrc1 = "../../../assets/image/arrow-down.png"'>
            Services<img [src]="imgSrc1"
                 (mouseover)='imgSrc1 = "../../../assets/image/arrow-down-active.svg"'
                 (mouseout)='imgSrc1 = "../../../assets/image/arrow-down.png"'
                 class="marl-8"
                 *ngIf="currentURL !== '/services'">
            <img src="../../../assets/image/arrow-down-active.svg"
                 class="marl-8"
                 *ngIf="currentURL === '/services'">
          </a>
          <mat-menu #menu1="matMenu"
                    class="dropdown">
            <a class="dropdown-item"
               [routerLink]="['/services']">Digital Acceleration</a>
          </mat-menu>
        </li>
        <li class="nav-item"
            *ngIf="currentURL !== '/blogs' && currentURL === '/blogsView'">
          <a class="nav-link"
             [ngClass]="{'menuActive':currentURL === '/blogsView'}"
             [routerLink]="['/blogs']">Blogs</a>
        </li>
        <li class="nav-item"
            *ngIf="currentURL !== '/blogsView'">
          <a class="nav-link"
             [ngClass]="{ 'menuActive':currentURL === '/blogs'}"
             [routerLink]="['/blogs']">Blogs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             [ngClass]="{'menuActive':currentURL === '/aboutUs'}"
             [routerLink]="['/aboutUs']">About
            Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             [ngClass]="{'menuActive':currentURL === '/contactUs'}"
             [routerLink]="['/contactUs']">Contact Us</a>
        </li>
      </ul>

      <div class="col-3text-right ">
        <button class="btn loginRegister"
                [mdePopoverTriggerFor]="appPopover"
                mdePopoverTriggerOn="hover">Login / Register</button>

        <mde-popover #appPopover="mdePopover"
                     [mdePopoverOverlapTrigger]="false"
                     [mdePopoverCloseOnClick]="false"
                     class="mdepopoverlogin">
          <mat-card style="max-width: 336px">
            <mat-card-content>
              We are working to bring you an excellent short credit facilitation platform for your working capital needs
              !!
              <br> Please signup your details or email us at <a href='#'
                 style="text-decoration: underline">contactus@enablere.com</a></mat-card-content>
            <!-- <mat-card-actions align="right">
              <button (click)="closePopover()" mat-button>Close</button>
              <button (click)="onSubmit()" mat-button>Submit</button>
            </mat-card-actions> -->
          </mat-card>
        </mde-popover>

      </div>
    </div>
  </nav>
</div>
