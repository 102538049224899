import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { commonLogoUpdate } from 'src/app/const/api.const';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LocalStorage } from '@ng-idle/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilitesService {
  constructor(
    private http: HttpClient,
    public router: Router,
  ) { }


  private isLoggedIn = new BehaviorSubject(false);
  getLoggedIn: any = this.isLoggedIn.asObservable();

  private isWebsiteobj = new BehaviorSubject(true);
  getisWebsiteobj: any = this.isWebsiteobj.asObservable();

  private isUserLoggedIn = new BehaviorSubject(false);
  getUserLoggedIn: any = this.isUserLoggedIn.asObservable();

  private isExpandedMenu = new BehaviorSubject('false');
  getisExpandedMenu: any = this.isExpandedMenu.asObservable();


  private logo$: BehaviorSubject<any> = new BehaviorSubject(false);
  public logo: Observable<any> = this.logo$.asObservable();

  private autoLogout: BehaviorSubject<any> = new BehaviorSubject(false);
  public autoSignout: Observable<any> = this.autoLogout.asObservable();

  currentUserId: any;

  validateAllFields(formGroup: FormGroup): any {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  public sendLoggedIn(data: boolean): any {
    this.isLoggedIn.next(data);
  }

  public userAfterLoggedIn(status): any {
    this.isUserLoggedIn.next(status);
  }

  public isWebsitefun(data: boolean): any {
    this.isWebsiteobj.next(data);
  }


  public setUserLogo(value: any): void {
    this.logo$.next(value);
  }

  error(error: any): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        localStorage.removeItem('menuSource');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('userType');
        localStorage.setItem('isSessionExpired', 'yes');
        window.location.href = 'digitalXchange/signin';
        // this.logo$.next(true);
      }
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\nerrormessage:${error.error.error_description}`;
    }
    return throwError(errorMessage);
  }

  public sendisExpandedMenu(data: string): any {
    this.isExpandedMenu.next(data);
  }

  public currencyFormatUSD(params: any): any {
    const inrFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return inrFormat.format(params);
  }

  getCommonLogo(): any {
    const url = environment.API_URL + commonLogoUpdate.getSiteSettingLogo;
    return this.http.get<any>(url).pipe(
      catchError(this.error)
    );
  }
  getLoggedInUserId(): any {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
      return userDetails.userId;
    } else {
      return '';
    }
  }

  getDiscountAmount(originalPrice, discount): any {
    const discountAmount = ((discount / 100) * originalPrice);
    return discountAmount;
  }

  getOfferedAmount(originalPrice, discount): any {
    const offeredAmount = originalPrice - ((discount / 100) * originalPrice);
    return offeredAmount;
  }

  getPlatformDiscountAmount(bidAmount, totalDiscount, platformDiscount): any {
    const buyerDiscount = totalDiscount - platformDiscount;
    const amount = this.getDiscountAmount(bidAmount, totalDiscount);
    const TotaldiscountAmount = this.getOfferedAmount(bidAmount, totalDiscount);
    const buyerDiscountAmount = this.getDiscountAmount(bidAmount, buyerDiscount);
    const platformDiscountAmount = this.getDiscountAmount(bidAmount, platformDiscount);
    return [TotaldiscountAmount, amount, buyerDiscountAmount, platformDiscountAmount];
  }

  getPricingModule(offerAmount, pricingPlan): any {
    const bidsamount = +offerAmount;
    let platformCharge = 0;
    pricingPlan.pricingPlans.forEach((value) => {
      if (bidsamount >= value.fromAmount && bidsamount <= value.toAmount) {
        platformCharge = value.platformCharges;
      }
    });
    return platformCharge;
  }

  downloadFormateChange(params): any {
    return params.substr(0, params.lastIndexOf('.')) + this.getFormattedTime() + '.pdf';
  }

  downloadGeneralDocument(params, type): any {
    return params.substr(0, params.lastIndexOf('.')) + this.getFormattedTime() + '.' + type;
  }

  public getFormattedTime(): any {
    const today = new Date();
    const y = today.getFullYear();
    // JavaScript months are 0-based.
    const m = today.getMonth() + 1;
    const d = today.getDate();
    const h = today.getHours();
    const mi = today.getMinutes();
    const s = today.getSeconds();
    return '_' + y + m + d + h + mi + s;
  }

  logout(): any {
    localStorage.removeItem('menuSource');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('userType');
    this.router.navigateByUrl('digitalXchange/signin');
  }
  getTokendx(): any {
    return localStorage.getItem('userDetails');
  }
  isDxLoggednIn(): any {
    return this.getTokendx() !== null;
  }

}
