import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitesService } from 'src/app/services/utilities.services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public currentURL: string;
  public hideHeader: boolean;
  public isWebsite: boolean;

  constructor(
    private router: Router,
    public utilitesService: UtilitesService
  ) {
    router.events.subscribe((val) => {
      this.currentURL = this.router.url;
    });
  }

  ngOnInit(): void {
    this.utilitesService.getisWebsiteobj.subscribe((data: boolean) => {
      this.isWebsite = data;
    });
    this.isWebsite = (localStorage.getItem('isWebsite') === 'true') ? true : false;

  }

}
