import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacypolicyComponent } from './component/privacypolicy/privacypolicy.component';
import { TermsconditionComponent } from './component/termscondition/termscondition.component';
import { BlogsViewComponent } from './component/blogs-view/blogs-view.component';
import { EmailVerificationComponent } from './component/email-verification/email-verification.component';
import { DxAuthGuard } from './guards/dx-auth.guard';
import { DxAuthRedirectGuard } from './guards/dx-auth-redirect.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./component/home/home.module').then(
  //       (mod) => mod.HomeModule
  //     ),
  // },
  {
    path: '',
    redirectTo: 'digitalXchange/signin',
    pathMatch: 'full',
  },
  // {
  //   path: 'product',
  //   loadChildren: () =>
  //     import('./component/product/product.module').then(
  //       (mod) => mod.ProductModule
  //     ),
  // },
  // {
  //   path: 'services',
  //   loadChildren: () =>
  //     import('./component/services/services.module').then(
  //       (mod) => mod.ServicesModule
  //     ),
  // },
  // {
  //   path: 'blogs',
  //   loadChildren: () =>
  //     import('./component/blogs/blogs.module').then(
  //       (mod) => mod.BlogsModule
  //     ),
  // },
  // {
  //   path: 'aboutUs',
  //   loadChildren: () =>
  //     import('./component/about-us/about-us.module').then(
  //       (mod) => mod.AboutUsModule
  //     ),
  // },
  // {
  //   path: 'contactUs',
  //   loadChildren: () =>
  //     import('./component/contactus/contact-us.module').then(
  //       (mod) => mod.ContactUsModule
  //     ),
  // },
  // {
  //   path: 'faq',
  //   loadChildren: () =>
  //     import('./component/faq/faq.module').then(
  //       (mod) => mod.FaqModule
  //     ),
  // },
  // {
  //   path: 'testimonial',
  //   loadChildren: () =>
  //     import('./component/testimonial/testimonial.module').then(
  //       (mod) => mod.TestimonialModule
  //     ),
  // },
  {
    path: 'digitalXchange/signin',
    loadChildren: () =>
      import('./component/signin/signin.module').then(
        (mod) => mod.SigninModule
      ),
    canActivate: [DxAuthRedirectGuard]
  },
  {
    path: 'digitalXchange/register',
    loadChildren: () =>
      import('./component/register/register.module').then(
        (mod) => mod.RegisterModule
      ),
    canActivate: [DxAuthRedirectGuard]
  },
  // {
  //   path: 'privacyPolicy',
  //   component: PrivacypolicyComponent,
  // },
  // {
  //   path: 'termsCondition',
  //   component: TermsconditionComponent,
  // },
  // {
  //   path: 'blogsView',
  //   component: BlogsViewComponent,
  // },
  {
    path: 'digitalXchange',
    loadChildren: () =>
      import('./admin-app/admin-app.module').then(
        (mod) => mod.AdminAppModule
      ),
    canActivate: [DxAuthGuard]
  },
  {
    path: 'digitalXchange/create-profile',
    loadChildren: () =>
      import('./component/create-profile/create-profile.module').then(
        (mod) => mod.CreateProfileModule
      ),
    canActivate: [DxAuthGuard]
  },
  {
    path: 'digitalXchange/emailVerification/:token',
    component: EmailVerificationComponent,
  },
  {
    path: 'digitalXchange/resetpassword/:token',
    loadChildren: () =>
      import('./component/resetpassword/reset.module').then(
        (mod) => mod.ResetPasswordModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
