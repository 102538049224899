import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WebAppApi, RegistrationApi, CreateProfileApi } from 'src/app/const/api.const';
import { UtilitesService } from 'src/app/services/utilities.services';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ContactUsService {

    constructor(
        private http: HttpClient,
        public utilitesService: UtilitesService
    ) { }

    sendContactUs(params: any): any {
        const url = environment.API_URL + WebAppApi.saveContactUs;
        return this.http.post<any>(url, params).pipe(
            catchError(this.utilitesService.error)
        );
    }

    verifyUserEmail(token: object): any {
        const url = environment.API_URL + WebAppApi.emailVerification;
        return this.http.put<any>(url, token).pipe(
            catchError(this.utilitesService.error)
        );
    }

    getRefreshToken(params: any): any {
        const url = environment.API_URL + RegistrationApi.signIn + '?client_id=' + params.client_id +
            '&client_secret=' + params.client_secret + '&grant_type=' + params.grant_type +
            '&refresh_token=' + params.refresh_token + '&username=' + params.username;
        return this.http.post<any>(url, {}).pipe(
            catchError(this.utilitesService.error)
        );
    }

}
