import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FooterComponent } from './component/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IdleLogoutModalComponent } from 'src/app/popup-component/idle-logout/idle-logout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PrivacypolicyComponent } from './component/privacypolicy/privacypolicy.component';
import { TermsconditionComponent } from './component/termscondition/termscondition.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SignaturePadModule } from 'ngx-signaturepad';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { ReactiveFormsModule } from '@angular/forms';
import { BlogsViewComponent } from './component/blogs-view/blogs-view.component';
import { EmailVerificationComponent } from './component/email-verification/email-verification.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr';
import { ContactUsService } from './component/contactus/service/contact-us.services';
import { JwtInterceptor } from './helper';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { NgxSignaturePadModule } from '@o.krucheniuk/ngx-signature-pad';
import { DxAuthGuard } from './guards/dx-auth.guard';
import { DxAuthRedirectGuard } from './guards/dx-auth-redirect.guard';

// Push Notification
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AdminheaderService } from './admin-app/adminheader/service/adminheader.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IdleLogoutModalComponent,
    PrivacypolicyComponent,
    TermsconditionComponent,
    BlogsViewComponent,
    EmailVerificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatButtonModule,
    NgbModule,
    MatCardModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    NgApexchartsModule,
    SignaturePadModule,
    FormsModule,
    MdePopoverModule,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 3000,
    }),
    MatTooltipModule,
    AnimateOnScrollModule.forRoot(),
    NgxSignaturePadModule,
    // Push Notifiactaion
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],

  providers: [
    ContactUsService,
    { provide: ToastrService, useClass: ToastrService },
    DxAuthGuard,
    DxAuthRedirectGuard, MessagingService, AsyncPipe, AdminheaderService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
