export const environment = {
  production: false,
  qa: false,
  API_URL: "http://54.251.188.92:8080/de/",
  captch_Key: "6Le9FlwaAAAAAEKSkFnsRZTXZ97eDtrUO4dAxQsd",
  //for Push Notification
  firebase: {
    apiKey: "AIzaSyC-R5Z3YErs3y7muWeLxf2dfSnGBH3euzg",
    authDomain: "digitalexchangepush.firebaseapp.com",
    projectId: "digitalexchangepush",
    storageBucket: "digitalexchangepush.appspot.com",
    messagingSenderId: "259271522903",
    appId: "1:259271522903:web:296b2dbcaf94ea59760112",
    measurementId: "G-G7BJ6RVM4P"
  }
};
